import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
    Wrapper: styled.div`
        width: 100%;
        display: flex;
        justify-content: center;
        background: linear-gradient(90deg, #253e38, #496860 100%);
    `,
    ServicesContainer: styled(Container)`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 90px 0 90px 0;
    `,
    H2Title: styled.h2`
        font-size: 36px;
        margin: 0;
        text-align: center;
        font-weight: normal;
        margin-top: 15px;
        padding: 0 15px 0 15px;
        @media (max-width: 500px) {
            font-size: 28px;
        }
    `,
    H3Title: styled.h3`
        font-size: 30px;
        margin: 45px 0 45px 0;
        text-align: center;
        font-weight: normal;
        padding: 0 15px 0 15px;
        @media (max-width: 500px) {
            font-size: 22px;
        }
    `,
    Cards: styled.div`
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
    `,
    Others: styled.div`
        width: 1000px;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        justify-content: center;
        @media (max-width: 1020px) {
            width: 100%;
        }
    `,
};

export default Styles;
