import React from "react";
import Styles from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { SVGIcon } from "../../Styled";
import Icons from "../../images/icons";
import { Element } from "react-scroll";

const { ContactWrapper, H3Title, Row, Text } = Styles;
const { greenPhone, greenEnvelope } = Icons;

const Contact = () => {
    const intl = useIntl();

    return (
        <Element name="contact">
            <ContactWrapper>
                <H3Title>Kontakt</H3Title>
                <address>
                    <Row>
                        <SVGIcon
                            src={greenEnvelope}
                            width="44px"
                            height="44px"
                            alt="email"
                        />
                        <Text>betgrotkowska@gmail.com</Text>
                    </Row>
                    <Row>
                        <SVGIcon
                            src={greenPhone}
                            width="44px"
                            height="44px"
                            alt="phone"
                        />
                        <Text>0043 699-191-225-15</Text>
                    </Row>
                </address>
            </ContactWrapper>
        </Element>
    );
};

export default Contact;
