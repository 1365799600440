import styled from "styled-components";

const Styles = {
    CardWrapper: styled.div`
        border: 2px solid ${(props) => props.theme.color.cream};
        border-radius: 28px;
        padding: 5px;
        width: 275px;
        height: 285px;
        margin: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        & > img {
            padding: 38px;
        }
        @media (max-width: 500px) {
            margin: 20px;
            width: 230px;
        }
    `,
    TextWrapper: styled.div`
        height: 110px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: center;
        bottom: 0;
    `,
    Text: styled.p`
        font-size: 18px;
        margin: 0;
        text-align: center;
        padding-left: 15px;
        padding-right: 15px;
    `,
};

export default Styles;
