import React from "react";
import Layout from "../components/Layout";
import Landing from "../components/Landing";
import Services from "../components/Services";
import Info from "../components/Info";
import Contact from "../components/Contact";

const IndexPage = () => (
    <Layout>
        <Landing />
        <Services />
        <Info />
        <Contact />
    </Layout>
);

export default IndexPage;
