import React from "react";
import Styles from "./styles";
import { GatsbyImage } from "gatsby-plugin-image";
import { useIntl } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";

const {
    DoubleWrapper,
    ImageWrapper,
    InfoWrapper,
    InfoText,
    QuestionsWrapper,
    QuestionText,
} = Styles;

const Info = () => {
    const intl = useIntl();
    const { allFile } = useStaticQuery(graphql`
        query infoQuery {
            allFile(filter: { name: { eq: "info" } }) {
                edges {
                    node {
                        childImageSharp {
                            gatsbyImageData(layout: CONSTRAINED)
                        }
                    }
                }
            }
        }
    `);
    return (
        <>
            <DoubleWrapper>
                <ImageWrapper>
                    <GatsbyImage
                        style={{
                            width: "100%",
                            maxHeight: "100%",
                            minHeight: "100%",
                        }}
                        image={
                            allFile.edges[0].node.childImageSharp
                                .gatsbyImageData
                        }
                        alt={intl.formatMessage({ id: "addInfo1" })}
                    />
                </ImageWrapper>
                <InfoWrapper>
                    <InfoText>
                        {intl.formatMessage({ id: "addInfo1" })}
                    </InfoText>
                    <InfoText>
                        {intl.formatMessage({ id: "addInfo2" })}
                    </InfoText>
                </InfoWrapper>
            </DoubleWrapper>
            <QuestionsWrapper>
                <QuestionText>
                    {intl.formatMessage({ id: "questions" })}{" "}
                    {intl.formatMessage({ id: "needTranslate" })}
                </QuestionText>
                <QuestionText>
                    {intl.formatMessage({ id: "order" })}
                </QuestionText>
            </QuestionsWrapper>
        </>
    );
};

export default Info;
