import Icons from "../../images/icons";

const { law, book, laptop, scroll, letter, gear, certificate, car } = Icons;

const ServiceCardsData = [
    {
        id: 1,
        icon: law,
        text: "card1",
    },
    {
        id: 2,
        icon: book,
        text: "card2",
    },
    {
        id: 3,
        icon: laptop,
        text: "card3",
    },
    {
        id: 4,
        icon: scroll,
        text: "card4",
    },
    {
        id: 5,
        icon: letter,
        text: "card5",
    },
    {
        id: 6,
        icon: gear,
        text: "card6",
    },
];

const OtherServicesCardsData = [
    {
        id: 1,
        icon: certificate,
        text: "card6",
    },
    {
        id: 2,
        icon: car,
        text: "card7",
    },
];

export { ServiceCardsData, OtherServicesCardsData };
