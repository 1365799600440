import React from "react";
import Styles from "./styled";
import ServiceCard from "./ServiceCard";
import { ServiceCardsData, OtherServicesCardsData } from "./data";
import IServiceCard from "./ServiceCard/types";
import { useIntl } from "gatsby-plugin-react-intl";
import { Element } from "react-scroll";

const { Wrapper, ServicesContainer, H2Title, H3Title, Cards, Others } = Styles;

const Services = () => {
    const intl = useIntl();
    return (
        <Element name="services">
            <Wrapper>
                <ServicesContainer>
                    <H2Title>
                        {intl.formatMessage({ id: "typeOfServices" })}
                    </H2Title>
                    <H3Title>{intl.formatMessage({ id: "service1" })}</H3Title>
                    <Cards>
                        {ServiceCardsData.map((card: IServiceCard) => (
                            <ServiceCard
                                key={card.id}
                                icon={card.icon}
                                text={card.text}
                            />
                        ))}
                    </Cards>
                    <H3Title>{intl.formatMessage({ id: "service2" })}</H3Title>
                    <Others>
                        {OtherServicesCardsData.map((card: IServiceCard) => (
                            <ServiceCard
                                key={card.id}
                                icon={card.icon}
                                text={card.text}
                            />
                        ))}
                    </Others>
                </ServicesContainer>
            </Wrapper>
        </Element>
    );
};

export default Services;
