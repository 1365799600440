import React from "react";
import Styles from "./styles";
import { SVGIcon } from "../../../Styled";
import IServiceCard from "./types";
import { useIntl } from "gatsby-plugin-react-intl";

const { CardWrapper, Text, TextWrapper } = Styles;

const ServiceCard = ({ icon, text }: IServiceCard) => {
    const intl = useIntl();
    return (
        <CardWrapper>
            <SVGIcon
                src={icon}
                width="142px"
                height="142px"
                alt={intl.formatMessage({ id: text })}
            />
            <TextWrapper>
                <Text>{intl.formatMessage({ id: text })}</Text>
            </TextWrapper>
        </CardWrapper>
    );
};

export default ServiceCard;
