import styled from "styled-components";
import { Container } from "../../Styled";

const Styles = {
    DoubleWrapper: styled.div`
        width: 100%;
        height: 470px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        @media (max-width: 740px) {
            grid-template-columns: 1fr;
            grid-template-rows: 1fr 1fr;
            height: 900px;
        }
    `,
    ImageWrapper: styled.div`
        width: 100%;
        height: 470px;
    `,
    InfoWrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px;
        @media (max-width: 500px) {
            padding: 100px 20px 100px 20px;
        }
    `,
    InfoText: styled.p`
        margin: 10px 0 10px 0;
        font-size: 18px;
        text-align: center;
        color: ${(props) => props.theme.color.green};
    `,
    QuestionsWrapper: styled.div`
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: linear-gradient(90deg, #253e38, #496860 100%);
        width: 100%;
        height: 320px;
    `,
    QuestionText: styled.p`
        margin: 15px;
        font-size: 30px;
        text-align: center;
        @media (max-width: 500px) {
            font-size: 22px;
        }
    `,
};

export default Styles;
